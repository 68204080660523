import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PressList from '../components/press/presslist';
import PressLearningForm from '../components/press/presslearinigform';
import settings from '../../settings';
import ImageBackground from "../components/imagebackground";

const banner =`${settings.IMAGES_BASE_URL}/v2/images/press-room.jpg`


const PressPage = () => {
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Press" description="Download our Press Kit with BMW Performance Center graphics, information and articles about us. Media inquiries? You're in the right place." />
      <div className="press">
        <ImageBackground
          imageDesktop={banner}
          imageMobile={banner}
          imageAlt="press"
          topLeftChildren={
            <div className="text_left">
              <div>PRESS ROOM</div>
            </div>
          }
          hideArrow
        />
        <div className="container">
          <div className='press_learning_more_form'>
              <h4>PRESS KIT</h4>
              <p>
              Fill out the form below and you’ll receive a link to download our media kit, including graphics, information and articles about us.
              </p>
              <PressLearningForm />
          </div>
          <h2>The LATEST NEWS ABOUT THE PERFORMANCE CENTER</h2>
          <PressList />

        </div>
      </div>
    </Layout>
  );
};

export default PressPage;
