import React from 'react';
import settings from '../../settings';
const FacebookEmbeddedPost = (props) => {
  const defaultbmwimage = `${settings.IMAGES_BASE_URL}/v2/images/home/desktop/bmw_car.jpeg`
  return (
    <div className="col-6">
      <div className="press__item">
        <div className="press__item__content">
          <div className="press__youtube">
            <img className="responsive-iframe" src={props.full_picture?props.full_picture:defaultbmwimage} alt="press-youtube" />
          </div>
          <h6>{props.id}</h6>
          <p>{props.message}</p>
          <a href={props.permalink_url} target="_blank" rel="noopener noreferrer">
            <button className="btn btn-bordered">Read Now</button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default FacebookEmbeddedPost;
